import React, { useEffect, useState } from "react";
import "./Resources.css";
import { Link } from "gatsby";

interface Post {
  ID?: string;
  title?: string;
  URL?: string;
  post_thumbnail?: {
    URL?: string;
  };
}

// @ts-ignore
const NavLink = (props: any) => <Link activeClassName="active" {...props} />;

export const Resources = () => {
  const [posts, setPosts] = useState<Post[]>([]);
  useEffect(() => {
    // Get posts from wordpress api
    fetch(
      `https://public-api.wordpress.com/rest/v1.1/sites/skodel.wordpress.com/posts/?number=3&pretty=true`
    )
      .then((response) => response.json())
      .then((resultData) => {
        setPosts(resultData.posts);
      });
  }, []);

  return (
    <div>
      <div className="resource-container">
        {posts.map((post) => {
          return post.URL && post.post_thumbnail && post.title ? (
            <a
              className="resource-card"
              href={post.URL}
              target="_blank"
              key={post.ID}
            >
              <img src={post.post_thumbnail?.URL} />
              <h3 className="resource-title">{post.title}</h3>
            </a>
          ) : (
            <></>
          );
        })}
      </div>
      <div className="button-container">
        <a
          href="https://blog.skodel.com"
          target="_blank"
          className="btn btn-main"
        >
          View all resources
        </a>
      </div>
    </div>
  );
};
