import React, { useState, useEffect } from "react";
import { Link } from "gatsby";

// Styles
import "./index.css";

// Components
import IndexLayout from "../layouts";
import Page from "../components/Page";
import StackedImage from "../components/StackedImage";
import FAQ from "../components/FAQ";
import BookDiscussion from "../components/BookDiscussion";

// Images and icons
import HomeHero from "../assets/images/home-hero.jpg";
import LeadingOrganisationStamford from "../assets/images/leading-organisation-stamford.png";
import LeadingOrganisationBalcombe from "../assets/images/leading-organisation-balcombe.jpg";
import LeadingOrganisationNextSmile from "../assets/images/leading-organisation-next-smile.png";
import LeadingOrganisationCorporate from "../assets/images/leading-organisation-corporate.png";
import LeadingOrganisationBenedict from "../assets/images/leading-organisation-benedict.png";
import LeadingCardBG from "../assets/images/leading-card-bg.png";
import SkodelStrategyCircle from "../assets/images/skodel-strategy-circle.svg";
import SkodelCollectCircle from "../assets/images/skodel-collect-circle.svg";
import SkodelResponseCircle from "../assets/images/skodel-response-circle.svg";
import SkodelReportCircle from "../assets/images/skodel-report-circle.svg";
import ServiceInfographicImage from "../assets/images/services-infographic-image.jpg";
import ServiceOverviewIcon from "../assets/images/services-overview-icon.png";
import ServiceOverviewChart from "../assets/images/services-overview-chart.png";
import InfographicImageLeft from "../assets/images/infographic-image-left.jpg";
import InfographicPieChart from "../assets/icons/chart-pie.svg";
import InfographicUserGroup from "../assets/icons/user-group.svg";
import InfographicImageRight from "../assets/images/infographic-image-right.jpg";
import BalcombeCaseStudy from "../assets/images/bgs-case-study.png";
import WorkUserGroup from "../assets/images/user-group-circle.svg";
import WorkBook from "../assets/images/book-circle.svg";
import StandardOSHA from "../assets/images/standard-osha.png";
import StandardISO from "../assets/images/standard-iso.png";
import StandardWHO from "../assets/images/standard-who.png";
import StandardUSPHS from "../assets/images/standard-usphs.png";
import TestimonialBG from "../assets/images/testimonial-bg.png";
import TestimonialQuote from "../assets/images/testimonial-quote-bg.png";
import AndrewFuller from "../assets/images/testimonial-andrew-fuller.jpg";
import AccordionArrow from "../assets/images/accordion-arrow.png";
import { Resources } from "../components/Resources";

// @ts-ignore
const NavLink = (props: any) => <Link activeClassName="active" {...props} />;

const LeadingOrganisationData = [
  {
    id: "OR0001",
    image: LeadingOrganisationNextSmile,
    title: "Next Smile Melbourne",
    description:
      "Uses Skodel to embed a sustainable and cost effective approach to worker consultation on psychosocial hazards.",
  },
  {
    id: "OR0002",
    image: LeadingOrganisationCorporate,
    title: "Corporate Edge",
    description:
      "Uses Skodel to simplify worker consultation on psychosocial hazards to make it more sustainable and engaging for staff.",
  },
  {
    id: "OR0003",
    image: LeadingOrganisationBenedict,
    title: "St Benedict's",
    description:
      "Uses Skodel to collect psychological safety data quickly so they can build reports for OFSTED safety inspections without excessive administrative time.",
  },
  {
    id: "OR0004",
    image: LeadingOrganisationBalcombe,
    title: "Balcombe Grammar",
    description:
      "Uses Skodel to maintain their psychosocial risk assessment in consultation with workers in a light touch manner.",
  },
  {
    id: "OR0005",
    image: LeadingOrganisationStamford,
    title: "Stamford",
    description:
      "Uses Skodel to build awareness of and support psychological safety in a busy school environment.",
  },
];

const ServicesData = [
  {
    id: "SR0001",
    image: SkodelStrategyCircle,
    title: "Save resources",
    description:
      "There’s no resource tradeoffs to achieve compliance so you can meet requirements without disrupting priorities.",
  },
  {
    id: "SR0002",
    image: SkodelCollectCircle,
    title: "Adopt a validated approach",
    description:
      "Adopt an approach to psychosocial risk assessments, worker consultation and risk scoring that has been developed and validated alongside regulators.",
  },
  {
    id: "SR0003",
    image: SkodelResponseCircle,
    title: "Reduce non-compliance risk",
    description:
      "Make the process so simple for yourself that it becomes a seamless part of your organisation. Eliminate the risks associated with inaction or delays.",
  },
  {
    id: "SR0004",
    image: SkodelReportCircle,
    title: "Zero disruption",
    description:
      "Our approach is efficient, simple and clear with zero disruption. Everything is designed and ready to go for you.",
  },
];

const WorksData = [
  {
    id: "WR0001",
    image: WorkBook,
    title: "Highly regulated industries",
    description:
      "Education, retail, health and human services, construction. These industries face tighter regulations and greater expectations to provide safe places to work.",
  },
  {
    id: "WR0002",
    image: WorkUserGroup,
    title: "Minimal time & resources",
    description:
      "Navigating what is required and how best to meet psychosocial compliance is a big time investment. Skodel can remove the time, effort and complexity in this.",
  },
  {
    id: "WR0003",
    image: SkodelCollectCircle,
    title: "Cost conscious",
    description:
      "Spending excessive amounts of money each year on consultants isn’t an option for all businesses. Skodel offers a more cost effective alternative.",
  },
];

const SafetyStandardsData = [
  {
    id: "SS0001",
    title: "OSHA",
    image: StandardOSHA,
  },
  {
    id: "SS0002",
    title: "ISO",
    image: StandardISO,
  },
  {
    id: "SS0003",
    title: "WHO",
    image: StandardWHO,
  },
  {
    id: "SS0004",
    title: "US Surgeon General",
    image: StandardUSPHS,
  },
];

const FAQData = [
  {
    id: "FAQ0001",
    question: "Can it be integrated?",
    answer:
      "Yes, we integrate with various platforms and maintain an open api. Please speak to us about our integrations.",
  },
  {
    id: "FAQ0002",
    question: "What support does Skodel provide?",
    answer:
      "We offer onboarding and ongoing technical support for clients using the Skodel system as well support in reviewing psychosocial data, scoring risks and delivering training.",
  },
];

const Home = () => {
  const [leadingOrganisation, setLeadingOrganisation] = useState<
    { id: string; image: string; title: string; description: string }[]
  >([]);
  const [services, setServices] = useState<
    { id: string; image: string; title: string; description: string }[]
  >([]);
  const [selectedService, setSelectedService] = useState<number>(0);
  const [works, setWorks] = useState<
    { id: string; image: string; title: string; description: string }[]
  >([]);
  const [selectedWork, setSelectedWork] = useState<number>(0);
  const [safetyStandards, setSafetyStandards] = useState<
    { id: string; title: string; image: string }[]
  >([]);
  const [faq, setFAQ] = useState<
    { id: string; question: string; answer: string | React.ReactNode }[]
  >([]);

  useEffect(() => {
    setLeadingOrganisation(LeadingOrganisationData);
    setServices(ServicesData);
    setWorks(WorksData);
    setSafetyStandards(SafetyStandardsData);
    setFAQ(FAQData);
  }, [
    setLeadingOrganisation,
    setServices,
    setWorks,
    setSafetyStandards,
    setFAQ,
  ]);

  return (
    <IndexLayout withBlackLogo={true} workplacesHeader={false}>
      <Page>
        <section className="section-home-hero">
          <div
            className="section-bg"
            style={{ backgroundImage: `url('${HomeHero}')` }}
          ></div>
          <div className="main-container">
            <div className="home-hero-content">
              <h1>Achieve continuous psychosocial compliance</h1>
              <p>
                Skodel helps busy people and safety leaders meet psychosocial
                requirements without disrupting other priorities.
              </p>
              <div className="home-hero-button">
                <NavLink to={"/request-a-demo"} className="btn btn-white">
                  Book A Demo
                </NavLink>
                <NavLink
                  target="_blank"
                  to={
                    "https://www.loom.com/share/1aeb83ef743e4f738ac3fd6a5d8651ce"
                  }
                  className="btn btn-outline-white btn-white"
                >
                  Our approach
                </NavLink>
              </div>
            </div>
          </div>
        </section>
        <section className="section-home-middle">
          <div className="main-container">
            <div className="home-middle-wrapper">
              <div className="home-organisation-wrapper">
                <div className="section-title text-center">
                  <h2>Meet compliance with confidence</h2>
                  <p>
                    Adopt an approach to psychosocial safety one regulator
                    describes as “the clearest and most effective approach”
                    they’ve ever seen.
                  </p>
                </div>
                <div className="home-organisation-grid">
                  {leadingOrganisation?.map((item, index) => (
                    <div
                      className="home-organisation-card"
                      key={item?.id + "-" + index}
                    >
                      <img src={item?.image} alt={item?.title} />
                      <div className="home-organisation-background">
                        <img src={LeadingCardBG} alt={"Skodel"} />
                      </div>
                      <div className="home-organisation-description">
                        <h3>{item?.title}</h3>
                        <p>{item?.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="home-report-wrapper">
                <div className="section-title text-center">
                  <h2>We’ve done the hard work for you</h2>
                  <p>
                    All the planning and tools to achieve psychosocial
                    compliance. Distilled into one easy-to-use document backed
                    by regulators.
                  </p>
                </div>
                <div className="home-report-content">
                  <div className="home-report-video">
                    <div className="video-wrapper">
                      <iframe
                        frameBorder="0"
                        width="100%"
                        height="600"
                        title="Skodel"
                        src="https://www.youtube.com/embed/EjNXegR3OKo?autoplay=0&fs=1&iv_load_policy=3&showinfo=0&rel=0&cc_load_policy=0&start=0&end=0"
                      />
                    </div>
                  </div>
                  <div className="home-report-services">
                    <div className="services-grid">
                      <div className="services-text">
                        <h3> Meet compliance effortlessly</h3>
                        <p>
                          You don’t have to shift priorities to achieve
                          psychosocial compliance. Skodel is simple and free
                          from costly resource tradeoffs.
                        </p>
                        <div className="services-accordion">
                          {services?.map((item, index) => (
                            <div
                              className={`${"accordion-item"} ${
                                selectedService === index ? "expand" : ""
                              }`}
                              key={item?.id + "-" + index}
                              onClick={() => setSelectedService(index)}
                            >
                              <div className="accordion-head">
                                <img src={item?.image} alt={item?.title} />
                                <h3>{item?.title}</h3>
                                <img
                                  className="accordion-arrow"
                                  src={AccordionArrow}
                                  alt={"Skodel"}
                                />
                              </div>
                              <div className="accordion-body">
                                <p>{item?.description}</p>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="services-image">
                        <img src={ServiceInfographicImage} alt={"Skodel"} />
                        <div className="services-overview">
                          <div className="services-overview-wrapper">
                            <div className="services-overview-icon">
                              <img src={ServiceOverviewIcon} alt={"Skodel"} />
                            </div>
                            <div className="services-overview-head">
                              <p>Mental health overview</p>
                            </div>
                            <div className="services-overview-body">
                              <img src={ServiceOverviewChart} alt={"Skodel"} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="stacked-content">
                    <StackedImage
                      image={BalcombeCaseStudy}
                      title={"Supporting BGS with Psychosocial Hazards"}
                      description={
                        "Balcombe Grammar wanted an efficient way to consult staff on psychosocial hazards. With 145 staff and one dedicated wellbeing leader, the process needed to be simple. We implemented a light touch quarterly check with data feeding into an action plan. It is allowing for essential worker consultation data to be maintained and up to date with minimal effort. Staff appreciate the speed and user experience which makes ongoing participation easy and leadership find value in a relevant set of data they can use to create positive change."
                      }
                      link={
                        "https://blog.skodel.com/balcombe-grammar-school-staff-wellbeing-case-study/"
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="home-reason-wrapper">
                <div className="section-title text-center">
                  <h2>Why choose Skodel</h2>
                  <p>
                    It will save you significant time and it has been built
                    alongside regulators. We remove the complexity and
                    uncertainty of finding out what you need to do and the best
                    way to do it.
                  </p>
                </div>
                <div className="home-reason-content">
                  <div className="reason-grid">
                    <div className="reason-image">
                      <div className="infographic-left">
                        <img src={InfographicImageLeft} alt={"Skodel"} />
                        <div className="infographic-left-detail">
                          <img src={InfographicPieChart} alt={"Skodel"} />
                          <h3>100%</h3>
                          <p>Customer satisfaction on support requests</p>
                        </div>
                      </div>
                      <div className="infographic-right">
                        <div className="infographic-right-detail">
                          <img src={InfographicUserGroup} alt={"Skodel"} />
                          <h3>88%</h3>
                          <p>Said Skodel improved wellbeing &amp; safety</p>
                        </div>
                        <img src={InfographicImageRight} alt={"Skodel"} />
                      </div>
                    </div>
                    <div className="reason-text">
                      <h3>For people and safety leaders</h3>
                      <p>
                        Skodel is for people and safety leaders in highly
                        regulated industries who need to meet psychosocial
                        compliance but have minimal time and resources to do so.
                      </p>
                      <div className="reason-accordion">
                        {works?.map((item, index) => (
                          <div
                            className={`${"accordion-item"} ${
                              selectedWork === index ? "expand" : ""
                            }`}
                            key={item?.id + "-" + index}
                            onClick={() => setSelectedWork(index)}
                          >
                            <div className="accordion-head">
                              <img src={item?.image} alt={item?.title} />
                              <h3>{item?.title}</h3>
                              <img
                                className="accordion-arrow"
                                src={AccordionArrow}
                                alt={"Skodel"}
                              />
                            </div>
                            <div className="accordion-body">
                              <p>{item?.description}</p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="home-standard-wrapper">
                <div className="section-title text-center">
                  <h2>
                    Our approach is guided by best practice safety standards
                  </h2>
                  <p>
                    Skodel helps organisations and leaders embed best practice
                    in psychosocial safety as per international standards and
                    legislation.
                  </p>
                </div>
                <div className="home-standard-grid">
                  {safetyStandards?.map((item, index) => (
                    <div
                      className="home-standard-card"
                      key={item?.id + "-" + index}
                    >
                      <img src={item?.image} alt={item?.title} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section-home-testimonial">
          <img src={TestimonialBG} alt={"Skodel"} />
          <div className="main-container">
            <div className="testimonial-wrapper">
              <div className="testimonial-text">
                <img src={TestimonialQuote} alt={"Skodel"} />
                <h3>Why we partnered with Andrew</h3>
                <p>
                  Skodel partners with clinical psychologist, Andrew Fuller, to
                  enhance user experience. Andrew has been described as someone
                  who “puts the heart back into psychology”. He is a clinical
                  psychologist and Fellow at the University of Melbourne.
                  Creating a great experience for people reduces the resistance
                  to participation that often increases workloads for leaders.
                  It can help turn what is traditionally a cumbersome compliance
                  exercise into a competitive advantage, boosting engagement,
                  retention and productivity. Andrew has helped Skodel build an
                  experience people and leaders will appreciate you for.
                </p>
                <p>
                  <strong>
                    Andrew Fuller, Clinical Psychologist &amp; Author
                  </strong>
                </p>
              </div>
              <div className="testimonial-image">
                <img src={AndrewFuller} alt={"Andrew Fuller"} />
              </div>
            </div>
          </div>
        </section>
        <section className="section-home-bottom">
          <div className="main-container">
            <div className="home-middle-wrapper">
              <div className="home-reason-wrapper">
                <div className="section-title text-center">
                  <h2>Skodel Resources</h2>
                  <p>
                    Navigate the challenging landscape of mental health best
                    practice and legislation by hearing from psychologists and
                    safety leaders.
                  </p>
                </div>
                <Resources />
              </div>
              <div className="home-faq-wrapper">
                <div className="section-title text-center">
                  <h2>Frequently asked questions</h2>
                </div>
                <div className="home-faq-content">
                  <div className="home-faq-list">
                    {faq?.map((item, index) => (
                      <FAQ
                        key={item?.id + "-" + index}
                        question={item?.question}
                        answer={item?.answer}
                      />
                    ))}
                  </div>
                  <BookDiscussion
                    title={"Book in a discussion"}
                    link={"/request-a-demo"}
                    button={"Request A Demo"}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </Page>
    </IndexLayout>
  );
};

export default Home;
